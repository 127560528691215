import * as Sentry from '@sentry/react';
import WebFont from 'webfontloader';
import Translation from 'components/data/Translation';
import { RegexHelpers } from 'helpers/regex.helpers';
import Template, { DesignerSettings, Layers, State, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import { GoogleFonts } from '../models/google-fonts';
import BrandGuide from '../types/brandGuide.type';
import FrameType from '../types/frameTypes.type';
import { isLayerProperties } from '../utils/typeGuards';
import LayerHelpers from './layer.helpers';
import { TextProperties } from '../types/layerProperties.type';
import { webSafeFonts } from '../config/fonts';
import { TemplateFont, MissingFont, MissingFontGrouped, FontToBeReplaced } from '../types/font.type';
import cloneDeep from '../utils/cloneDeep';
import Layer from '../types/layer.type';
import TemplateDesignerStore from '../data/template-designer-store';
import { DynamicLayerInput } from '../types/dynamicLayer.type';
import FormatHelpers from './format.helpers';

class FontHelpers {
    /**
     * Load in Google fonts into the template.
     * @param fontFamilies - The Google font families that you want to load.
     */
    static loadGoogleFonts = (fontFamilies: string[]): void => {
        try {
            const googleFonts = GoogleFonts.get();

            const foundFonts = fontFamilies.reduce((all, fontFamily) => {
                const foundFont = googleFonts.find((googleFont) => googleFont.family === fontFamily);

                if (foundFont) {
                    all.push(`${foundFont.family}:${foundFont.variants.join(',')}`);
                }

                return all;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, [] as any[]);

            WebFont.load({
                google: {
                    families: foundFonts
                }
            });
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    /**
     * Load in brand guide fonts into the template.
     * @param fonts - The brand guide fonts that you want to load.
     */
    static loadBrandGuideFonts = async (fonts?: BrandGuide['fonts']): Promise<void> => {
        if (fonts === undefined) fonts = getTemplateData<BrandGuide['fonts']>('brandGuide.fonts');
        if (fonts === undefined) return;

        const fontPromises = fonts.map(async (font) => {
            if (!font || (!font.key && !font.value) || !font.fontSrc) return;

            try {
                const fontFace = new FontFace(font.key, `url(${font.fontSrc})`);
                await fontFace.load();
                document.fonts.add(fontFace);
            } catch (error) {
                Sentry.captureException(error);
            }
        });

        await Promise.all(fontPromises);
    };

    /**
     * Gets the brand guide font from the font families.
     * @param fontFamilies - The brand guide font families that you want to load.
     * @returns The brand guide font object.
     */
    static getBrandGuideFontsFromFontFamilies = (fontFamilies: string[]): BrandGuide['fonts'] => {
        const brandGuide = getTemplateData<Template['brandGuide']>('brandGuide');

        if (!brandGuide || !brandGuide.fonts) {
            throw new Error('No brand guide fonts available');
        }

        return fontFamilies.reduce(
            (all, fontFamily) => {
                const brandGuideFont = brandGuide.fonts.find((font) => font.key === fontFamily || font.value === fontFamily);

                if (brandGuideFont) {
                    all.push(brandGuideFont);
                }

                return all;
            },
            [] as BrandGuide['fonts']
        );
    };

    /**
     * Update the missing fonts list.
     */
    static updateMissingFonts = (): void => {
        const missingFonts = getTemplateData<State['missingFonts']>('state.missingFonts');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const formats = getTemplateData<Template['formats']>('formats');
        const layers = getTemplateData<Template['layers']>('layers');
        const frameLayers = layers[frameType];

        formats.forEach((format) => {
            frameLayers.forEach((layer) => {
                const key = `${frameType}.${format.key}.${layer.key}`;
                // Delete missing fonts that has been removed based on frame, format and layer key.
                const updatedMissingFonts = (missingFonts || []).filter((missingFont) => missingFont.key !== key);
                TemplateDesignerStore.save(['state.missingFonts', updatedMissingFonts], { saveToHistory: true });
            });
        });
    };

    /**
     * Groups the missing fonts per font instead of per location where a missing font is found.
     * @param missingFonts - List of missing fonts.
     * @returns An object with per font family name a list of layers, formats and frames.
     */
    static generateMissingFontsGrouped = (missingFonts: MissingFont[]): { [key: string]: MissingFontGrouped } => {
        const missingFontsGrouped: { [key: string]: MissingFontGrouped } = {};

        missingFonts.forEach((missingFont) => {
            const missingFontData: MissingFontGrouped = missingFontsGrouped[missingFont.fontFamily] || {
                layers: [],
                formats: [],
                frames: [],
                fontFamily: missingFont.fontFamily,
                fontName: missingFont.fontName,
                fontSource: missingFont.fontSource
            };

            const [frame, format, layer] = missingFont.key.split('.');

            missingFontData.layers = [...new Set([...missingFontData.layers, layer])];
            missingFontData.formats = [...new Set([...missingFontData.formats, format])];
            missingFontData.frames = [...new Set([...missingFontData.frames, frame])];

            missingFontsGrouped[missingFont.fontFamily] = missingFontData;
        });

        return missingFontsGrouped;
    };

    /**
     * Replace a missing font with a new font.
     * @param replaceFont - The font that needs to be replaced.
     * @returns The new layer properties.
     */
    static replaceMissingFont = (replaceFont: FontToBeReplaced, layerProperties: Template['layerProperties']): Template['layerProperties'] => {
        if (!layerProperties) {
            layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        }

        const designerSettings = getTemplateData<DesignerSettings>('designerSettings');
        const brandGuide = getTemplateData<Template['brandGuide']>('brandGuide');

        const { fontFamily, fontSource, newVariant, newFontFamily, newFontName, newFontSource } = replaceFont;
        const newLayerProperties = cloneDeep(layerProperties);

        // loop over all the formats
        Object.keys(newLayerProperties).forEach((formatKey) => {
            const formatData = newLayerProperties[formatKey];

            // loop over all the frames in a specific format
            Object.keys(formatData).forEach((frameKey) => {
                if (frameKey === 'base' && designerSettings.disableBase) return;

                const frameData = formatData[frameKey];

                // loop over all the layers in a specific frame
                Object.keys(frameData)
                    .filter((layerKey) => layerKey !== 'properties')
                    .forEach((layerKey) => {
                        const layerData = frameData[layerKey];

                        const properties = layerData.properties;

                        // if there are no properties set in an array we return early
                        if (!properties) return;

                        const textStyling = properties.textStyling;

                        // only check the textstyling property as this is the only place where we can set the font family
                        if (!textStyling) return;

                        // loop over all the set text styling (normal or highlighted)
                        Object.keys(textStyling).forEach((textStylingKey) => {
                            const textStylingData = cloneDeep(textStyling[textStylingKey]);
                            const oldFontFamily = textStylingData.fontFamily;
                            const oldFontSource = textStylingData.fontSource;

                            if (!oldFontFamily || !oldFontSource) return;

                            if (oldFontSource.includes('brandGuide')) {
                                const foundFont =
                                    brandGuide &&
                                    brandGuide.fonts &&
                                    brandGuide.fonts.find((item) => item.key === oldFontFamily || item.value === oldFontFamily);
                                // if the set font is not found in the brand guide data add it to the missing font to show a warning to the user
                                if (!foundFont) {
                                    if (oldFontFamily === fontFamily && oldFontSource === fontSource) {
                                        textStylingData.fontFamily = newFontFamily;
                                        textStylingData.fontSource = newFontSource;
                                        textStylingData.fontVariant = newVariant;
                                        textStylingData.fontName = newFontName;
                                    }
                                }
                            }

                            if (fontSource === 'googleFonts') {
                                const foundFont = GoogleFonts.get().filter((item) => item.family === fontFamily)[0];
                                // if the set font is not found in the google fonts data add it to missing fonts to show a warning to the user
                                if (!foundFont) {
                                    if (oldFontFamily === fontFamily && oldFontSource === fontSource) {
                                        textStylingData.fontFamily = newFontFamily;
                                        textStylingData.fontSource = newFontSource;
                                        textStylingData.fontVariant = newVariant;
                                    }
                                }
                            }

                            textStyling[textStylingKey] = textStylingData;
                        });
                    });
            });
        });

        return newLayerProperties;
    };

    /**
     * Get all the web safe font values.
     * @returns Object with all the web safe font values.
     */
    static getWebSafeFontValues = (): { [font: string]: string } => {
        return webSafeFonts.reduce((all, font) => {
            all[font.value] = font.value;
            return all;
        }, {});
    };

    /**
     * Get all fonts used in either the template or the interface setup.
     * @param template - The template.
     * @returns All fonts used in the template.
     */
    static getAllFonts = (template: Template): TemplateFont[] => {
        // Get all fonts used by layers.
        const { foundFonts } = this.getUsedFonts(template, template.brandGuide);

        // Get all fonts used by interface setup.
        const dynamicLayerFonts: TemplateFont[] = this.getFontsFromDynamicLayers(template.dynamicLayers)
            // Filter out already used fonts.
            .filter((font) => {
                if (foundFonts.find((existingFont) => existingFont.font === font)) return false;
                if (webSafeFonts.find((webSafeFont) => webSafeFont.value === font)) return false;
                return true;
            })
            // Map to the correct format.
            .map((font) => {
                let foundFont = GoogleFonts.get().filter((item) => item.family === font)[0];

                if (!foundFont) {
                    foundFont = template.brandGuide && template.brandGuide.fonts && template.brandGuide.fonts.find((item) => item.value === font);

                    if (!foundFont) return;

                    return {
                        type: 'brandGuide',
                        src: foundFont.fontSrc,
                        font: foundFont.value,
                        key: foundFont.key,
                        variants: ['regular']
                    };
                }

                return {
                    key: foundFont.key,
                    type: 'googleFonts',
                    font: foundFont.family,
                    variants: foundFont.variants
                };
            })
            // Filter out undefined values.
            .filter((font) => font !== undefined) as TemplateFont[];

        return [...foundFonts, ...dynamicLayerFonts];
    };

    /**
     * Gets all fonts used in the dynamic layers.
     * @param dynamicLayers The complete dynamic layers object
     * @returns Array of font names used in the dynamic layers
     */
    private static getFontsFromDynamicLayers = (dynamicLayers?: Template['dynamicLayers']): string[] => {
        if (dynamicLayers === undefined) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');

        const fonts: string[] = [];

        /**
         * Recursively check for fonts in the dynamic layers.
         * @param inputs - The inputs to check for fonts.
         */
        function getFontsFromFrameType(inputs: DynamicLayerInput[]) {
            inputs.forEach((input) => {
                if ('children' in input && input.children.length > 0) {
                    getFontsFromFrameType(input.children);
                }

                if (input.type === 'field' && input.attribute === 'fontFamily' && input.settings && 'options' in input.settings) {
                    const inputFonts = (input.settings.options as { key: string; value: string }[]).map((option) => option.value);
                    fonts.push(...inputFonts);
                }
            });
        }

        Object.keys(dynamicLayers).forEach((frameType) => {
            dynamicLayers && getFontsFromFrameType(dynamicLayers[frameType]);
        });

        const uniqueFonts = Array.from(new Set(fonts));

        return uniqueFonts;
    };

    /**
     * Get the used and missing fonts in the template.
     * @param template - The whole template.
     * @param brandGuide - The brand guide.
     * @returns Object with used and missing fonts.
     */
    static getUsedFonts = (template: Template, brandGuide?: Template['brandGuide']): { foundFonts: TemplateFont[]; missingFonts: MissingFont[] } => {
        if (brandGuide === undefined) brandGuide = getTemplateData<Template['brandGuide']>('brandGuide');

        const { layerProperties, layers, frameTypes, designerSettings } = template;

        const foundFonts: TemplateFont[] = [];
        const missingFonts: MissingFont[] = [];

        // loop over all the formats
        Object.keys(layerProperties).forEach((formatKey) => {
            const formatData = layerProperties[formatKey];

            // loop over all the frames in a specific format
            Object.keys(formatData).forEach((frameKey) => {
                if (frameKey === 'base' && designerSettings.disableBase) return;

                const frameData = formatData[frameKey];

                // loop over all the layers in a specific frame
                Object.keys(frameData)
                    .filter((layerKey) => layerKey !== 'properties')
                    .forEach((layerKey) => {
                        const layerData = frameData[layerKey];

                        if (layerData === undefined) return;
                        if (!isLayerProperties(layerData)) return;

                        const layer = LayerHelpers.findLayer(layerKey, frameKey);

                        if (!layer || layer.type !== 'text') return;

                        const properties = layerData.properties as TextProperties;

                        // if there are no properties set in an array we return early
                        if (!properties) return;

                        const textStyling = properties.textStyling;

                        // only check the textstyling property as this is the only place where we can set the font family
                        if (!textStyling) return;

                        // loop over all the set text styling (normal or highlighted)
                        Object.keys(textStyling).forEach((textStylingKey) => {
                            const textStylingData = textStyling[textStylingKey];

                            const fontFamily = textStylingData.fontFamily;
                            const fontName = textStylingData.fontName;
                            const fontSource = textStylingData.fontSource;

                            if (!fontFamily || !fontSource) return;

                            if (fontSource.indexOf('brandGuide') > -1) {
                                const foundFontKey = brandGuide && brandGuide.fonts && brandGuide.fonts.find((item) => item.key === fontFamily);
                                const foundFontValue = brandGuide && brandGuide.fonts && brandGuide.fonts.find((item) => item.value === fontFamily);

                                // If the set font is not found in the brand guide data add it to the missing font to show a warning to the user
                                if (!foundFontKey && !foundFontValue) {
                                    const path = this.createPathMissingFont(frameTypes, frameKey, formatKey, layers, layerKey);
                                    const key = `${frameKey}.${formatKey}.${layerKey}`;

                                    if (missingFonts.find((missingFont) => missingFont.key === key)) return;

                                    missingFonts.push({
                                        key,
                                        frameType: frameKey,
                                        format: formatKey,
                                        layer: layerKey,
                                        path,
                                        fontFamily,
                                        fontSource,
                                        fontName
                                    });
                                    return;
                                }

                                // If the font is already used on another place dont add it for a second time.
                                if (foundFonts.find((font) => (font.font === fontFamily || font.key === fontFamily) && font.type === 'brandGuide')) return;

                                // First check for key.
                                if (foundFontKey) {
                                    foundFonts.push({
                                        type: 'brandGuide',
                                        key: foundFontKey.key,
                                        font: foundFontKey.value,
                                        variants: ['regular'],
                                        src: foundFontKey.fontSrc
                                    });
                                } else if (foundFontValue) {
                                    // If key is not found check for value (for backwards compatibility)
                                    foundFonts.push({
                                        type: 'brandGuide',
                                        key: foundFontValue.key,
                                        font: foundFontValue.value,
                                        variants: ['regular'],
                                        src: foundFontValue.fontSrc
                                    });
                                }
                            }

                            if (fontSource === 'googleFonts') {
                                const foundFont = GoogleFonts.get().filter((item) => item.family === fontFamily)[0];
                                // if the set font is not found in the google fonts data add it to missing fonts to show a warning to the user
                                if (!foundFont) {
                                    const path = this.createPathMissingFont(frameTypes, frameKey, formatKey, layers, layerKey);

                                    if (missingFonts.find((missingFont) => missingFont.path === path)) return;

                                    missingFonts.push({
                                        key: `${frameKey}.${formatKey}.${layerKey}`,
                                        frameType: frameKey,
                                        format: formatKey,
                                        layer: layerKey,
                                        path,
                                        fontFamily,
                                        fontSource
                                    });
                                    return;
                                }

                                // If the font is already used on another place dont add it for a second time.
                                if (foundFonts.find((font) => font.font === fontFamily && font.type === 'googleFonts')) return;

                                foundFonts.push({
                                    type: 'googleFonts',
                                    font: foundFont.family,
                                    variants: foundFont.variants,
                                    key: foundFont.key,
                                    src: ''
                                });
                            }
                        });
                    });
            });
        });

        // If the last used font is missing remove the last used font.
        const lastUsedFont = getTemplateData<State['lastUsedFont']>();
        if (lastUsedFont) {
            const lastUsedFontIsMissing = missingFonts.some((missingFont) => !lastUsedFont.fontFamily || missingFont.fontFamily === lastUsedFont.fontFamily);
            if (lastUsedFontIsMissing) {
                TemplateDesignerStore.save(['state.lastUsedFont', null], { saveToHistory: false });
            }
        }

        return { foundFonts, missingFonts };
    };

    /**
     * Create the path for the missing font.
     * @param frameTypes - The frame types.
     * @param frameKey - The frame key.
     * @param formatKey - The format key.
     * @param layers - The layers.
     * @param layerKey - The layer key.
     * @returns The path for the missing font.
     */
    private static createPathMissingFont = (frameTypes: FrameType[], frameKey: string, formatKey: string, layers: Layers, layerKey: string) => {
        const frameName = (() => {
            const frame = frameTypes.find((frame) => frame.key === frameKey);
            if (!frame) return frameKey;
            return frame.title;
        })();

        const formatName = FormatHelpers.getFormatName(formatKey);

        const layerPath = LayerHelpers.findLayerPath(layers[frameKey], layerKey);
        if (!layerPath) return '';

        const parsedLayerPath = layerPath.replace(/\./g, ' -> ');
        const path = `${frameName} -> ${formatName} -> ${parsedLayerPath}`;
        return path;
    };

    /**
     * Remove a layer from the missing fonts list.
     * @param layerKey - The layer key that you want to remove from the missing fonts list.
     */
    static removeLayerFromMissingFonts = (layerKey: Layer['key']): void => {
        const missingFonts = getTemplateData<State['missingFonts']>('state.missingFonts');

        if (!missingFonts || !missingFonts.length) return;

        const newMissingFonts = missingFonts.filter((missingFont) => !missingFont.key.includes(layerKey));

        TemplateDesignerStore.save(['state.missingFonts', newMissingFonts], { saveToHistory: false });
    };

    /**
     * Gets the last used font.
     * @returns The last used font.
     */
    static getLastUsedFont = (): State['lastUsedFont'] => {
        const lastUsedFont = getTemplateData<State['lastUsedFont']>('state.lastUsedFont');
        return lastUsedFont;
    };

    /**
     * Sets the last used font
     * @param font - The font that needs to be set as the last used font.
     */
    static setLastUsedFont = (font: State['lastUsedFont']): void => {
        const lastUsedFont = FontHelpers.getLastUsedFont();
        TemplateDesignerStore.save(['state.lastUsedFont', { ...lastUsedFont, ...font }], { saveToHistory: false });
    };

    /**
     * Parse a google font variant to a human readable font variant.
     * @param title The google font variant title
     * @returns Human readable font variant
     */
    static parseGoogleFontVariant = (title: string): string => {
        const weightMapping: { [key: string]: string } = {
            '100': Translation.get('sidebarRight.inputs.fontSizeWeights.thin', 'template-designer'),
            '200': Translation.get('sidebarRight.inputs.fontSizeWeights.extraLight', 'template-designer'),
            '300': Translation.get('sidebarRight.inputs.fontSizeWeights.light', 'template-designer'),
            '400': Translation.get('sidebarRight.inputs.fontSizeWeights.normal', 'template-designer'),
            '500': Translation.get('sidebarRight.inputs.fontSizeWeights.medium', 'template-designer'),
            '600': Translation.get('sidebarRight.inputs.fontSizeWeights.semiBold', 'template-designer'),
            '700': Translation.get('sidebarRight.inputs.fontSizeWeights.bold', 'template-designer'),
            '800': Translation.get('sidebarRight.inputs.fontSizeWeights.extraBold', 'template-designer'),
            '900': Translation.get('sidebarRight.inputs.fontSizeWeights.black', 'template-designer')
        };

        // Extract the numeric part of the weight (e.g., "500" from "500italic")
        const numericWeight = RegexHelpers.extractMatches('containsNumbers', title)[0];

        if (!numericWeight || !weightMapping[numericWeight]) return title.charAt(0).toUpperCase() + title.slice(1);

        const textWeight = weightMapping[numericWeight];

        // Get the additional text part, if any (e.g., "italic" in "500italic")
        const additionalText = RegexHelpers.replaceMatches('containsNumbers', title, '').trim();

        // Append additional text with a space if it exists
        const output = additionalText ? `${textWeight} ${additionalText.charAt(0).toUpperCase() + additionalText.slice(1)}` : textWeight;

        return output;
    };
}

export { FontHelpers };
