import Setup from 'components/data/Setup';
import { Features } from '../types/features.type';

class FeatureHelpers {
    /**
     * Get the Template Designer setup.
     * @returns The Template Designer setup.
     */
    private static getTemplateDesignerSetup = () => {
        return Setup.get('templateDesigner');
    };

    /**
     * Check if a feature is enabled.
     * @param feature - The feature to check.
     * @returns True if the feature is enabled, false otherwise.
     */
    static hasFeature = (feature: Features): boolean => {
        const setup = FeatureHelpers.getTemplateDesignerSetup();
        if (setup === undefined || setup.features === undefined) return false;
        return setup.features.includes(feature);
    };
}

export { FeatureHelpers };
